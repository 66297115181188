import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'
import { Link as GatsbyLink } from "gatsby"


export const Container = styled.div`
  position: fixed;
  /* align-self: center; */
  height: 100%;
  width: 100%;
  top: 0;
  background: white;
  overflow: hidden;
  z-index: 2;
  @media (max-width: 960px) {
    overflow-y: scroll;
  }
`

export const Link = styled(GatsbyLink)`
  color: black;
  text-decoration: none;
`
export const Btn = styled(Link)`
  font-family: "Times New Roman", Times, serif;
  @media (max-width: 960px) {
    font-size: 22px;
  }
  background: white;
  border-color: none;
  border-style: none;
  border-width: none;
  border-radius: none;
  :hover {
    font-style: italic;
    color: black;
  }
  cursor: pointer;
`
export const Rows = styled.div`
  top: ${props => props.theme.headerHeight};
  display: flex;
  flex-direction: column;
  padding-top:3.5vh;
`
export const MenuBar = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.theme.headerHeight};
  @media (max-width: 960px) {
    height: 5vh;
  }
  background: white;
  overflow: hidden;
`

export const P = styled.p`
  letter-spacing: 0.05em;
  line-height: 1em;
`

export const Row = styled.div`
  display: flex;
  min-height: 0;
  flex: 1;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`

const Imprint = () => (
  <Layout>
    <SEO
      title="&#8203;"
      keywords={[
      ]}
    />
    <Container>
      <MenuBar>
      <Link to="/">Imprint</Link>
      <Btn to="/">CLOSE</Btn>
    </MenuBar>
<p>
<Row>&#8203;</Row> 
<Row>Contact Details</Row>
<Row>E-Mail: <a href="mailto:hello@juliarosenstock.com">hello@juliarosenstock.com</a></Row>
<Row>Instagram: <a href="https://www.instagram.com/giuliarosenstock/">@giuliarosenstock</a></Row>
<Row>&#8203;</Row>
<Row>Web Design</Row>
<Row>Julia Rosenstock</Row>
<Row>&#8203;</Row>
<Row>Website Development</Row>
<Row> <a href="mailto:simon@accosine.com">Simon Bugert</a>* and Julia Rosenstock</Row>
<Row>&#8203;</Row>
<Row>Text</Row>
<Row><a href="https://carolineballegaard.com/">Caroline Marie Ballegaard</a>* and Julia Rosenstock</Row>
<Row>&#8203;</Row>
<Row>Web Typography/UI</Row>
<Row>Johannes Schmoll*</Row>
<Row>&#8203;</Row>
<Row>Angaben gemäß § 5 TMG</Row>
<Row>Julia Rosenstock</Row>
<Row>Postanschrift: Kiehlufer 43, Fabrikgebäude, 12059 Berlin</Row>
<Row>Vertreten durch Julia Rosenstock</Row>
<Row>Eingetragen am Finanzamt Neukölln 16/488/00847</Row>
<Row>Urheberrechtliche Hinweise</Row>
<Row>Julia Rosenstock</Row>
</p> 
  </Container>
  </Layout>
)

export default Imprint